<template>
  <div>
    <div class="card">
      <div class="card-header">
        {{ workspace.name }}
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col d-flex justify-content-center align-items-center">
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-warning rounded-circle"
                  style="width: 48px; height: 48px"
                  ><span class="b-avatar-custom"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trending-up"
                    >
                      <polyline
                        points="23 6 13.5 15.5 8.5 10.5 1 18"
                      ></polyline>
                      <polyline points="17 6 23 6 23 12"></polyline></svg></span
                  ><!----></span
                >
              </div>
              <div class="media-body my-auto">
                <h5 class="font-weight-bolder mb-0">Total Pending Tasks</h5>
                <p
                  class="card-text font-small-3 mb-0"
                  v-for="(key, value) in sharedTaskCount"
                  :key="key"
                >
                  <span v-if="value == 1">{{ key }}</span>
                </p>
              </div>
            </div>
            <!-- <div class="box">
              <span style="font-weight: 600; text-transform: capitalize">
                my task pending
              </span>
              
              <div v-for="(key, value) in mytasks" :key="key">
                <span
                  v-if="value == 1"
                  style="font-size: 18px; font-weight: 600px"
                  >{{ key }}</span
                >
               
              </div>
            </div> -->
          </div>
          <div class="col d-flex justify-content-center align-items-center">
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-primary rounded-circle"
                  style="width: 48px; height: 48px"
                  ><span class="b-avatar-custom"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trending-up"
                    >
                      <polyline
                        points="23 6 13.5 15.5 8.5 10.5 1 18"
                      ></polyline>
                      <polyline points="17 6 23 6 23 12"></polyline></svg></span
                  ><!----></span
                >
              </div>
              <div class="media-body my-auto">
                <h5 class="font-weight-bolder mb-0">My Tasks Pending</h5>
                <p
                  class="card-text font-small-3 mb-0"
                  v-for="(key, value) in mytasks"
                  :key="key"
                >
                  <span v-if="value == 1">{{ key }}</span>
                </p>
              </div>
            </div>
            <!-- <div class="box">
              <span style="font-weight: 600; text-transform: capitalize">
                my task pending
              </span>
              
              <div v-for="(key, value) in mytasks" :key="key">
                <span
                  v-if="value == 1"
                  style="font-size: 18px; font-weight: 600px"
                  >{{ key }}</span
                >
               
              </div>
            </div> -->
          </div>
          <div class="col d-flex justify-content-center align-items-center">
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-warning rounded-circle"
                  style="width: 48px; height: 48px"
                  ><span class="b-avatar-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-calendar"
                    >
                      <rect
                        x="3"
                        y="4"
                        width="18"
                        height="18"
                        rx="2"
                        ry="2"
                      ></rect>
                      <line x1="16" y1="2" x2="16" y2="6"></line>
                      <line x1="8" y1="2" x2="8" y2="6"></line>
                      <line x1="3" y1="10" x2="21" y2="10"></line></svg></span
                  ><!----></span
                >
              </div>
              <div class="media-body my-auto">
                <h5 class="font-weight-bolder mb-0">Assigned Tasks Pending</h5>
                <p
                  class="card-text font-small-3 mb-0"
                  v-for="(key, value) in assignedtasks"
                  :key="key"
                >
                  <span v-if="value == 1">{{ key }}</span>
                </p>
              </div>
            </div>
            <!-- <div class="box">
              <span style="font-weight: 600; text-transform: capitalize">
                assigned tasks pending
              </span>
              <br />
              <div v-for="(key, value) in assignedtasks" :key="key">
                <span
                  v-if="value == 1"
                  style="font-size: 18px; font-weight: 600px"
                  >{{ key }}</span
                >
               
              </div>
              
            </div> -->
          </div>
          <div class="col d-flex justify-content-center align-items-center">
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-success rounded-circle"
                  style="width: 48px; height: 48px"
                  ><span class="b-avatar-custom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-shield"
                    >
                      <path
                        d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"
                      ></path></svg></span
                  ><!----></span
                >
              </div>
              <div class="media-body my-auto">
                <h5 class="font-weight-bolder mb-0">
                  On-going Gap Assessments
                </h5>
                <p class="card-text font-small-3 mb-0">
                  {{ gapassessments.total }}
                </p>
              </div>
            </div>
            <!-- <div class="box">
             
              <span style="font-weight: 600; text-transform: capitalize"
                >on going gap assessments</span
              >
              <br />
              <span style="font-size: 18px; font-weight: 600px">
                {{ gapassessments.total }}</span
              >
            </div> -->
          </div>
          <div class="col d-flex">
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-secondary rounded-circle"
                  style="width: 48px; height: 48px"
                  ><span class="b-avatar-custom"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trending-down"
                    >
                      <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
                      <polyline
                        points="17 18 23 18 23 12"
                      ></polyline></svg></span
                  ><!----></span
                >
              </div>
              <div class="media-body my-auto">
                <h5 class="font-weight-bolder mb-0">Current Gap</h5>
                <p class="card-text font-small-3 mb-0">
                {{gapassessments.gap%gapassessments.totalControls>0?(gapassessments.gap%gapassessments.totalControls).toFixed(2):0}}
                  <!-- {{gapassessments.gap}}%({{ gapassessments.totalControls }}
                  controls) -->
                </p>
              </div>
            </div>
            <!-- <div class="box">
              <span style="font-weight: 600; text-transform: capitalize">
                Current gap
              </span>
              <br />
              <span style="font-size: 18px; font-weight: 600px">
                {{ gapassessments.gap }}%({{
                  gapassessments.totalControls
                }}
                controls)</span
              >
            </div> -->
          </div>
          <div class="col d-flex justify-content-center align-items-center">
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-primary rounded-circle"
                  style="width: 48px; height: 48px"
                  ><span class="b-avatar-custom"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-x-circle"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="15" y1="9" x2="9" y2="15"></line>
                      <line x1="9" y1="9" x2="15" y2="15"></line></svg></span
                  ><!----></span
                >
              </div>
              <div class="media-body my-auto">
                <h5 class="font-weight-bolder mb-0">Open Risks</h5>
                <p class="card-text font-small-3 mb-0">{{ risks.total }}</p>
              </div>
            </div>
            <!-- <div class="box">
              <span style="font-weight: 600; text-transform: capitalize">
                Open Risks
              </span>
              <br />
              <span style="font-size: 18px; font-weight: 600px">
                {{ risks.total }}
              </span>
            </div> -->
          </div>
          <div class="col d-flex justify-content-center align-items-center">
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-danger rounded-circle"
                  style="width: 48px; height: 48px"
                  ><span class="b-avatar-custom"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-x-octagon"
                    >
                      <polygon
                        points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"
                      ></polygon>
                      <line x1="15" y1="9" x2="9" y2="15"></line>
                      <line x1="9" y1="9" x2="15" y2="15"></line></svg></span
                  ><!----></span
                >
              </div>
              <div class="media-body my-auto">
                <h5 class="font-weight-bolder mb-0">Critical Risk</h5>
                <p class="card-text font-small-3 mb-0">
                  {{ risks.criticalRisks }}
                </p>
              </div>
            </div>
            <!-- <div class="box">
              <span style="font-weight: 600; text-transform: capitalize">
                critical risk
              </span>
              <br />
              <span style="font-size: 18px; font-weight: 600px">
                {{ risks.criticalRisks }}
              </span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tenantuid: this.workspace.tenant_uid,
      taskssummary: [],
      global_dashboard_data: [],
      mytasks: [],
      assignedtasks: [],
      gapassessments: [],
      risks: [],
      sharedTaskCount:[],
    };
  },
  props: {
    workspace: Object,
  },
  watch: {
    tenantuid() {
      this.getGlobalDashboard(this.tenantuid);
    },
  },
  mounted() {
    this.getGlobalDashboard(this.tenantuid);
  },
  methods: {
    getGlobalDashboard(tenantuid) {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          cid: tenantuid,
        },
        url: `${process.env.VUE_APP_BASEURL}/global-dashboard`,
      };
      this.$http(options)
        .then((resp) => {
          this.global_dashboard_data = resp.data.data;
          this.mytasks = resp.data.data.myTasks;
          this.assignedtasks = resp.data.data.assignedTasks;
          this.gapassessments = resp.data.data.ongoingGapAssessments;
          this.risks = resp.data.data.risks;
          this.sharedTaskCount = resp.data.data.sharedTaskCount;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    gotoCiso(tenantid) {
      this.$store.commit("SET_TENANT_ID", tenantid);
      window.open(process.env.VUE_APP_CISOGRC_WEB_URL);
    },
    getTaskSummary(tenantuid) {
      // console.log(process.env.VUE_APP_BASEURL)
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          cid: tenantuid,
        },
        url: `${process.env.VUE_APP_CISOGRC_API_URL}/tasks/summary`,
      };
      this.$http(options)
        .then((resp) => {
          this.taskssummary = resp.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
  },
};
</script>
<style>
.taskcount {
  text-align: center;
}
</style>
