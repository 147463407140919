<template>
  <div>
   
    <!-- {{this.$store.state.app.user.user_id}} -->
    <div class="card"  v-for="(workspace, index) in this.$store.state.app.workspaces"
                :key="workspace">
      <Workspace :workspace="workspace"/>
    </div>
    <!-- <div class="row">
    
      <div class="col-12 mb-1"><b>MY TASKS</b></div>
      <div class="col-2 mytask-card" v-for="(key, value) in mytasks" :key="key">
        <div class="card">
          <span style="font-weight: 600; font-size: 17px">
            {{
              value == "1"
                ? "PENDING"
                : value == "2"
                ? "IN PROGRESS"
                : value == "3"
                ? "IN REVIEW"
                : value == "4"
                ? "APPROVED"
                : value == "5"
                ? "REJECTED"
                : ""
            }}
          </span>
          <span>
            {{ key }}
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mb-1"><b>ASSIGNED TASKS</b></div>
      <div
        class="col-2 mytask-card"
        v-for="(key, value) in assignedtasks"
        :key="key"
      >
        <div class="card">
          <span style="font-weight: 600; font-size: 17px">
            {{
              value == "1"
                ? "PENDING"
                : value == "2"
                ? "IN PROGRESS"
                : value == "3"
                ? "IN REVIEW"
                : value == "4"
                ? "APPROVED"
                : value == "5"
                ? "REJECTED"
                : ""
            }}
          </span>
          <span>
            {{ key }}
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <span class="mb-1"><b>ON GOING ASSESSMENTS</b></span>
        <div
          class="card p-1 mt-1"
          style="height: 100px; display: flex; justify-content: center"
        >
          <div class="row">
            <div class="col-4">
              <span style="font-size: 18px">
                <span style="font-weight: 600"> Total</span> :{{
                  gapassessments.total
                }}</span
              >
            </div>
            <div class="col-4">
              <span style="font-size: 18px">
                <span style="font-weight: 600">Total Controls</span> :
                {{ gapassessments.totalControls }}</span
              >
            </div>
            <div class="col-4">
              <span style="font-size: 18px">
                <span style="font-weight: 600">Gap:</span>
                {{ gapassessments.gap }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <b>RISKS</b>
        <div
          class="card mt-1 p-1"
          style="height: 100px; display: flex; justify-content: center"
        >
          <div class="row">
            <div class="col-6">
              <span style="font-size: 18px">
                <span style="font-weight: 600">Total</span> :
                {{ risks.total }}</span
              >
            </div>
            <div class="col-6">
              <span style="font-size: 18px"
                ><span style="font-weight: 600">Critical Risk</span> :
                {{ risks.criticalRisks }}</span
              >
            </div>
          </div>

          
        </div>
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-8">
        <div class="card" v-if="userIsVendor() == false">
          <div class="card-body">
            <h4>Pending Tasks</h4>
            <button
              class="btn btn-primary btn-sm float-right mb-1"
              @click="gotoTask()"
            >
              View All
            </button>
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Task</th>
                    <th scope="col">Due Date</th>
                    <th scope="col">Priority</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(task, index) in tasks" :key="task._id">
                    <th scope="row" style="width: 60px">{{ index + 1 }}</th>
                    <td>
                      <p class="font-weight-bolder mb-0">{{ task.title }}</p>
                    </td>
                    <td>
                      <p class="font-weight-bold mb-0">
                        {{ task.due_date | moment }}
                      </p>
                    </td>
                    <td>
                      <b-badge variant="danger" v-if="task.priority == 3">
                        High
                      </b-badge>
                      <b-badge variant="warning" v-if="task.priority == 2">
                        Medium
                      </b-badge>
                      <b-badge variant="success" v-if="task.priority == 1">
                        Low
                      </b-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card" v-if="userIsVendor() == false">
          <div class="card-body">
            <h4>Ongoing Gap Assessments</h4>
            <button
              class="btn btn-primary btn-sm float-right mb-1"
              @click="gotogaps()"
            >
              View All
            </button>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col" class="text-center">Progress</th>
                    <th scope="col" class="text-center">Controls</th>
                    <th scope="col" class="text-center">Gap</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(gap, index) in this.gaps"
                    :key="gap._id"
                    @click="handleRowClick(gap._id)"
                    class="cursor-pointer"
                  >
                    <th scope="row">{{ index + 1 }}</th>
                    <td style="width: 120px">
                      <p
                        class="mb-0 font-weight-bolder text-truncate"
                        style="width: 120px"
                        v-b-tooltip.hover.top.v-secondary
                        :title="gap.title"
                      >
                        {{ gap.title }}
                      </p>
                    </td>

                    <td>
                     
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <progress-badge
                          :value="gap.completion"
                          :colorsReversed="true"
                          :min="0"
                          :max="100"
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <b-avatar
                          :text="gap.total_controls.toString()"
                          variant="light-primary"
                        />
                      </div>
                    </td>
                    <td>
                     
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <progress-badge
                          :value="gap.gap.toFixed(0)"
                          :min="0"
                          :max="100"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card" v-if="userIsVendor() && trpAssessments !== null">
          <div
            class="card-header d-flex flex-row align-items-center justify-content-between"
          >
            <h4 class="mb-0">Ongoing T.P.R Assessments</h4>
            <b-button
              variant="primary"
              size="sm"
              @click="handleVendorAssessmentViewAllClick()"
              >View All</b-button
            >
          </div>
          <div class="card-body">
            <table role="table" class="table table-hover">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">Name</th>
                  <th role="columnheader" scope="col">Description</th>
                  <th role="columnheader" scope="col" class="text-center">
                    Questionnaires
                  </th>

                  <th role="columnheader" scope="col" class="text-center">
                    Progress
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <tr
                  v-for="assessment in trpAssessments"
                  :key="assessment._id"
                  role="row"
                  class="cursor-pointer"
                  @click="handleAssessmentClick(assessment._id)"
                >
                  <td role="cell" style="width: 120px">
                    <div
                      class="d-flex justify-content-start"
                      style="width: 120px"
                      v-b-tooltip.hover.top.v-secondary
                      :title="assessment.title"
                    >
                      <p class="font-weight-bolder text-truncate mb-0">
                        {{ assessment.title }}
                      </p>
                    </div>
                  </td>

                  <td role="cell" style="width: 120px">
                    <div
                      class="d-flex justify-content-start"
                      style="width: 120px"
                      v-b-tooltip.hover.top.v-secondary
                      :title="assessment.description"
                    >
                      <p class="font-weight-bold text-truncate mb-0">
                        {{ assessment.description }}
                      </p>
                    </div>
                  </td>

                  <td role="cell">
                    <div class="d-flex justify-content-center">
                      <b-avatar
                        :text="assessment.total_questionnaires.toString()"
                        variant="light-primary"
                      />
                    </div>
                  </td>

                  <td role="cell">
                    <div class="d-flex justify-content-center">
                      
                      <progress-badge
                        :colorsReversed="true"
                        :value="assessment.completion.toFixed(0)"
                        :min="0"
                        :max="100"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <h4>
              <b>{{ greeting }}</b>
            </h4>

            <h5>
              {{ $store.state.app.user.firstname }}
              {{ $store.state.app.user.lastname }}!
            </h5>

            <h1
              class="mb-75 mt-2 pt-50"
              v-if="greeting == 'Good Morning'"
              style="font-size: 2.5rem; float: right"
            >
              🌼
            </h1>
            <h1
              class="mb-75 mt-2 pt-50"
              v-if="greeting == 'Good Afternoon'"
              style="font-size: 2.5rem; float: right"
            >
              🌞
            </h1>
            <h1
              class="mb-75 mt-2 pt-50"
              v-if="greeting == 'Good Evening'"
              style="font-size: 2.5rem; float: right"
            >
              ☕️
            </h1>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h6>Upcoming Activities</h6>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import DonutBar from "../../components/DonutBar.vue";
import ProgressBadge from "../../components/ProgressBadge.vue";

import UserMixins from "@/mixins/UserMixins";
import moment from "moment";
import { BTable, BAvatar, BBadge, BButton, VBTooltip } from "bootstrap-vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import ThirdPartyRisksMixins from "../../mixins/ThirdPartyRisksMixins";
import Workspace from './Workspace.vue'
export default {
  components: {
    DonutBar,
    BTable,
    BAvatar,
    BBadge,
    moment,
    BButton,
    ProgressBadge,
    Workspace
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      sideImg: require("@/assets/images/pages/coming-soon.svg"),
      tasks: [],
      gaps: [],
      trpAssessments: null,
      global_dashboard_data: [],
      mytasks: [],
      assignedtasks: [],
      gapassessments: [],
      risks: [],
    };
  },
  computed: {
    greeting() {
      var myDate = new Date();
      var hrs = myDate.getHours();

      var greet;

      if (hrs < 12) greet = "Good Morning";
      else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
      else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";
      return greet;
    },
  },

  mounted() {
    // this.load();
    this.getGlobalDashboard();
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  mixins: [UserMixins, ResponseMixins, ThirdPartyRisksMixins],
  methods: {
    getGlobalDashboard() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/global-dashboard`,
      };
      this.$http(options)
        .then((resp) => {
          this.global_dashboard_data = resp.data.data;
          this.mytasks = resp.data.data.myTasks;
          this.assignedtasks = resp.data.data.assignedTasks;
          this.gapassessments = resp.data.data.ongoingGapAssessments;
          this.risks = resp.data.data.risks;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    load() {
      this.getCurrentUserDetails()
        .then((res) => {
          this.$store.dispatch("app/setCurrentUserDetails", res.data.data, {
            root: true,
          });
        })
        .catch((err) => {
          this.handleError(err);
        });
      if (this.userIsVendor() == true) {
        this.getTPRAssessmentsAndSetData(
          { page: 1, limit: 5 },
          this.$store.state.app.user.vendor_id
        );
      } else {
        this.getTasks();
        this.getGaps();
      }
    },
    userIsVendor() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        return true;
      } else {
        return false;
      }
    },
    getTPRAssessmentsAndSetData(filters, vendorId) {
      this.getAssessmentsOfVendor(filters, vendorId)
        .then((res) => {
          this.trpAssessments = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
    handleVendorAssessmentViewAllClick() {
      this.$router.push(`/vendorassessments`);
    },
    handleAssessmentClick(id) {
      this.$router.push(`/vendorassessments/${id}`);
    },
    handleRowClick(id) {
      this.$router.push({
        name: "control-gap-asssessment",
        params: { id: id },
      });
    },
    getTasks() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/tasks?page=1&status=1&limit=5`,
      };
      this.$http(options)
        .then((resp) => {
          this.tasks = resp.data.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    getGaps(uid) {
      console.log("Get Gaps", "1675938132510");
      console.log(this.$store.state.app.user.user_id + "inside function");
      const options = {
        method: "GET",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        url: `${process.env.VUE_APP_BASEURL}/gap-assessments`,
        params: {
          limit: 5,
          assessed_by: this.$store.state.app.user.user_id,
        },
      };
      this.$http(options)
        .then((resp) => {
          this.gaps = resp.data.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    gotoTask() {
      this.$router.push("/tasks");
    },
    gotogaps() {
      this.$router.push("/gapassessment");
    },
  },
};
</script>

<style lang="scss" scoped>
.misc-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mytask-card {
  //   background: ;

  .card {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.box{
    // border: 1px solid #ccc;
    // height: 100px;
    // padding: 5px;
}
</style>
